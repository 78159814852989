import { asyncLoadFile } from '@shein/common-function'
import { PROD_SRC_DPA_ID, SANDBOX_SRC_DPA_ID, DEFAULT_LOCALE } from './constant'

export class InitChannelSdk {
  #SDK_MAP = {
    'sandbox-c2p': `https://sandbox.src.mastercard.com/srci/integration/2/lib.js?srcDpaId=${SANDBOX_SRC_DPA_ID}&locale=${DEFAULT_LOCALE}`,
    'c2p': `https://src.mastercard.com/srci/integration/2/lib.js?srcDpaId=${PROD_SRC_DPA_ID}&locale=${DEFAULT_LOCALE}`,
    'c2p-ui-kit': 'https://src.mastercard.com/srci/integration/components/src-ui-kit/src-ui-kit.esm.js',
  }

  #STYLE_MAP = {
    'c2p-ui-kit-style': 'https://src.mastercard.com/srci/integration/components/src-ui-kit/src-ui-kit.css',
  }

  #PromiseStatus = {}

  #SdkLoadPromiseMap = {}

  #C2P_SDKS = ['c2p']

  #SENDBOX_C2P_SDKS = ['sandbox-c2p']

  #C2P_UI_KIT_SDKS = [{ sdkName: 'c2p-ui-kit', type: 'javascript', overwriteAttr: { type: 'module', async: false } }, { sdkName: 'c2p-ui-kit-style', type: 'style' }]

  #createAsyncLoadFilePromise({ sdkName, type, overwriteAttr }: { sdkName: string; type: 'javascript' | 'style'; overwriteAttr?: Record<string, any> }) {
    const sdk = this.#SDK_MAP[sdkName] || this.#STYLE_MAP[sdkName]
    const status = this.#PromiseStatus[sdkName]
    if (status === 'fulfilled') return Promise.resolve(true)
    else if (this.#SdkLoadPromiseMap[sdkName] && status === 'pending') return this.#SdkLoadPromiseMap[sdkName]
    this.#PromiseStatus[sdkName] = 'pending'

    const createLoadFileConfig = ({ sdkName, type, sdk, overwriteAttr = {} }: { sdkName: string; type: 'javascript' | 'style'; sdk: string; overwriteAttr?: Record<string, any> }): {
      label: string;
      attrs: {
        src?: string;
        href?: string;
        id: string;
        rel?: string;
        type?: string;
        style?: string;
        async?: string;
      };
    } => {
      if (type === 'style') {
        return {
          label: 'link',
          attrs: {
            rel: 'stylesheet',
            href: sdk,
            id: `channel-${sdkName}`,
            async: 'async',
          },
        }
      }
      return {
        label: 'script',
        attrs: {
          src: sdk,
          id: `channel-${sdkName}`,
          async: 'async',
          ...(overwriteAttr || {}),
        },
      }
    }

    const config = createLoadFileConfig({ sdkName, type, sdk, overwriteAttr })
    // console.log('createLoadFileConfig=====', config)
    return this.#SdkLoadPromiseMap[sdkName] = asyncLoadFile(config)
  }

  #checkSDKLoaded(sdkNames) {
    return sdkNames.every(sdkName => this.#PromiseStatus[sdkName] === 'fulfilled')
  }

  #createLoadSDKPromise(sdks) {
    return Promise.allSettled(sdks.map(sdkInfo => this.#createAsyncLoadFilePromise({
      sdkName: sdkInfo.sdkName || sdkInfo,
      type: sdkInfo?.type || 'script',
      overwriteAttr: sdkInfo?.overwriteAttr || {},
    })))
  }
  async #initSdk(sdks) {
    if (this.#checkSDKLoaded(sdks)) {
      return { datas: true }
    }

    const result = await this.#createLoadSDKPromise(sdks)
    result.forEach((res, index) => {
      this.#PromiseStatus[sdks[index]] = res.status
    })
    return { datas: true }
  }

  public async initC2pSdk(options?: { isSandBox?: boolean }) {
    return await this.#initSdk(options?.isSandBox ? this.#SENDBOX_C2P_SDKS : this.#C2P_SDKS)
  }

  public async initC2pUIKit() {
    return await this.#initSdk(this.#C2P_UI_KIT_SDKS)
  }
}
