export const ALL_LOCALES = [
  'bg_BG',
  'cs_CZ',
  'da_DK',
  'de_AT',
  'de_BE',
  'de_CH',
  'de_DE',
  'el_GR',
  'en_AE',
  'en_AU',
  'en_BE',
  'en_BH',
  'en_CA',
  'en_CH',
  'en_CZ',
  'en_DE',
  'en_EG',
  'en_ES',
  'en_GB',
  'en_HK',
  'en_HR',
  'en_IE',
  'en_IL',
  'en_IN',
  'en_IT',
  'en_JO',
  'en_KE',
  'en_KW',
  'en_NG',
  'en_NO',
  'en_NZ',
  'en_PH',
  'en_PL',
  'en_QA',
  'en_RS',
  'en_SA',
  'en_SE',
  'en_SG',
  'en_TH',
  'en_TZ',
  'en_US',
  'en_VN',
  'es_AR',
  'es_CL',
  'es_CO',
  'es_DO',
  'es_EC',
  'es_ES',
  'es_GT',
  'es_HN',
  'es_MX',
  'es_NI',
  'es_PA',
  'es_PE',
  'es_PY',
  'es_SV',
  'es_UY',
  'et_EE',
  'fi_FI',
  'fr_BE',
  'fr_CA',
  'fr_CH',
  'fr_FR',
  'hu_HU',
  'hr_HR',
  'it_CH',
  'it_IT',
  'lt_LT',
  'lv_LV',
  'ms_MY',
  'nb_NO',
  'nl_BE',
  'nl_NL',
  'pl_PL',
  'pt_BR',
  'pt_PT',
  'ro_RO',
  'sk_SK',
  'sr_RS',
  'sv_SE',
  'sv_FI',
  'th_TH',
  'uk_UA',
  'vi_VN',
  'zh_HK',
  'zh_SG',
]

export const DEFAULT_LOCALE = 'en_US'

export const WEB_DPA_LOCALE_MAP = {
  en: 'en_GB',
  'ar-en': 'en_GB',
  asia: 'en_GB',
  ca: 'en_CA',
  eur: 'en_GB',
  ilen: 'en_GB',
  in: 'en_IN',
  se: 'en_SE',
  sg: 'en_SG',
  then: 'en_GB',
  za: 'en_GB',
  us: 'en_US',
  nz: 'en_NZ',
  iden: 'en_GB',
  my: 'en_GB',
  jpen: 'en_GB',
  roe: 'en_GB',
  ar: 'en_GB',
  maar: 'es_AR',
  cl: 'es_MX',
  enes: 'es_MX',
  es: 'es_ES',
  uses: 'es_MX',
  eurfr: 'fr_FR',
  cafr: 'fr_CA',
  fr: 'fr_FR',
  ma: 'fr_FR',
  de: 'de_DE',
  eurde: 'en_DE',
  ch: 'en_DE',
  'ch-fr': 'fr_CH',
  nl: 'nl_NL',
  eurnl: 'nl_NL',
  au: 'en_AU',
  br: 'pt_BR',
  hk: 'zh_HK',
  il: 'lt_LT',
  it: 'it_IT',
  mx: 'es_MX',
  pl: 'pl_PL',
  ru: 'en_GB',
  sesv: 'es_SV',
  th: 'th_TH',
  tr: 'en_GB',
  tw: 'zh_HK',
  uk: 'en_GB',
  vn: 'en_GB',
  'enpt-pt': 'pt_PT',
  'pt-pt': 'pt_PT',
  'eurpt-pt': 'pt_PT',
  'eurcs-cz': 'cs_CZ',
  'eurel-gr': 'el_GR',
  'eurbg': 'bg_BG',
  asiaja: 'en_GB',
  asiako: 'en_GB',
  kr: 'en_GB',
  kren: 'en_GB',
  ph: 'en_GB',
  pt: 'pt_PT',
  jp: 'en_GB',
  euqs: 'en_GB',
  'euqsel-gr': 'el_GR',
  'euqscs-cz': 'cs_CZ',
  'euqsfr': 'fr_FR',
  'euqsnl': 'nl_NL',
  'euqshu': 'hu_HU',
  'euqssk': 'sk_SK',
  co: 'es_MX',
  pe: 'es_MX',
  arg: 'es_MX',
  ecu: 'es_MX',
  ro: 'ro_RO',
  at: 'de_AT',
}
