import { type LibsClickToPay } from '../types'

export interface TransformedCardData extends LibsClickToPay.MaskedCard {
  id: LibsClickToPay.MaskedCard['srcDigitalCardId'];
  card_bin: string;
  last_four_no: string;
  card_no: string;
  app_logo: string;
  web_logo: string;
  card_type: LibsClickToPay.MaskedCard['paymentCardDescriptor']; // 卡类型映射，可能为空
  srcDigitalCardId: string;
  status: string;
}

// 实现一个函数，将卡token数据转换成另一个数据结构
export const transformCardData = (cardDataList?: LibsClickToPay.MaskedCard[]): TransformedCardData[] => {
  if (!cardDataList || !cardDataList?.length) return [] as TransformedCardData[]

  const originArr: TransformedCardData[] = cardDataList?.map?.(cardData => ({
    ...cardData,
    id: cardData.srcDigitalCardId || '',
    card_bin: cardData?.panBin || '',
    last_four_no: cardData.panLastFour || '',
    card_no: cardData.panLastFour ? `****${cardData.panLastFour || ''}` : '',
    app_logo: cardData.digitalCardData.artUri,
    web_logo: cardData.digitalCardData.artUri,
    card_type: cardData.paymentCardDescriptor || '',
    srcDigitalCardId: cardData.srcDigitalCardId || '',
    status: cardData.digitalCardData.status, // 卡状态 'ACTIVE' | 'EXPIRED'
  })) || []

  // 保证卡状态为 ACTIVE 的排在前面
  const activeArr: TransformedCardData[] = []
  const expiredArr: TransformedCardData[] = []
  for (let i = 0; i < originArr.length; i++) {
    if (originArr[i].status === 'ACTIVE') {
      activeArr.push(originArr[i])
    } else {
      expiredArr.push(originArr[i])
    }
  }
  return [...activeArr, ...expiredArr]
}

export const formatCheckoutResponse = (response: LibsClickToPay.CheckoutResponseParameters): {
  status: LibsClickToPay.C2pApiResponse['status'],
  fail_tips: string,
  data?: Record<string, any>,
  originData?: LibsClickToPay.CheckoutResponseParameters
} => {
  if (!response) return { status: 'fail', fail_tips: 'Checkout failed' }
  // https://developer.mastercard.com/unified-checkout-solutions/documentation/sdk-reference/checkout-with-card/#response-parameters
  const {
    checkoutActionCode = '',
    checkoutResponseData,
    headers,
  } = response || {}

  let status: LibsClickToPay.C2pApiResponse['status'] = 'error'
  let data = {}
  let fail_tips = ''

  if (checkoutActionCode === 'COMPLETE') {
    status = 'success'
    data = {
      srcFlowId: headers?.['x-src-cx-flow-id'] || '',
      correlationId: checkoutResponseData?.srcCorrelationId || '',
      merchantTransactionId: headers?.['merchant-transaction-id'] || '',
      tokenList: transformCardData(checkoutResponseData?.maskedCard ? [checkoutResponseData?.maskedCard] : []),
    }
    fail_tips = ''
  }

  if (checkoutActionCode === 'CANCEL') {
    status = 'cancel'
    fail_tips = 'Checkout canceled'
  }

  if (checkoutActionCode === 'ERROR') {
    status = 'fail'
    fail_tips = 'Checkout failed'
  }

  return {
    status,
    fail_tips,
    data,
    originData: response,
  }
}

export function generateUUIDv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (char) {
    const random = (Math.random() * 16) | 0
    const value = char === 'x' ? random : (random & 0x3) | 0x8 // 确保 y 值为 [8, 9, A, B]
    return value.toString(16)
  })
}
