
import { type LibsClickToPay } from '../types'
import { InitChannelSdk } from './channel'
export { DEFAULT_LOCALE, ALL_LOCALES, WEB_DPA_LOCALE_MAP } from './locales'
export { C2pCheckoutService, RECOGNITION_TOKEN_KEY, DEFAULT_CARD_BRANDS } from './c2pCheckoutService'

export const SUPPORT_CARD_BRANDS: Array<LibsClickToPay.CardBrands> = [
  'mastercard',
  'visa',
  'amex',
  'discover',
  'maestro',
]

export const loadC2pSdk = new InitChannelSdk()
